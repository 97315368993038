// Generic imports
import React, { useEffect, useState, useCallback } from "react"
import { useSpring, animated } from "react-spring"
import styled from "styled-components"
import { MdClose } from "react-icons/md"
import { connect } from "react-redux"
import { navigate, Link } from "gatsby"

// Other components imports
import { AccountStyles } from "components/Account/index"
import Button from "components/Button"
import Image from "components/image"
import Input from "components/Reusable/Input"
import MyGoogleLogin from "components/Account/GoogleLogin"
import { useFormik } from "formik"

// Redux imports
import * as sessionSelectors from "rdx/slices/session/selectors"
import * as sessionThunks from "rdx/slices/session/thunks"

// Static imports

// Utils imports
import { redirectWithHistory } from "services/utils"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {
  login: sessionThunks.login,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 25px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`

function Modal({
  login,
  user,
  showModalLogin,
  setShowModalLogin,
  setShowModalRegister,
  redirectTo,
}) {
  const [loading, setLoading] = useState(false)
  const [isUsingGoogle, setIsUsingGoogle] = useState(false)
  const [isLoggingIn, setIsLoggingIn] = useState(false)

  useEffect(() => {
    if (isLoggingIn && user.email) {
      formik.setFieldValue("email", "")
      formik.setFieldValue("password", "")
      setIsLoggingIn(false)
      setShowModalLogin(false)
      if (user.is_verified) {
        if (user.occupation) {
          if (redirectTo) {
            redirectWithHistory(redirectTo)
          } else {
            navigate("/")
          }
        } else {
          navigate("/verification?step=2")
        }
      } else {
        if (isUsingGoogle) {
          sessionThunks.sendVerificationEmail(user.email).finally(() => {
            setTimeout(() => {
              navigate("/verification")
            }, 1000)
          })
        }
      }
    }
  }, [user, isLoggingIn])

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: values => {
      setIsLoggingIn(true)
      setLoading(true)
      login(values).finally(() => {
        setLoading(false)
      })
    },
  })

  const animation = useSpring({
    config: {
      duration: 250,
    },
    position: "relative",
    opacity: showModalLogin ? 1 : 0,
    top: showModalLogin ? `0%` : `-100%`,
  })

  const keyPress = useCallback(
    e => {
      if (e.key === "Escape" && showModalLogin) {
        setShowModalLogin(false)
      }
    },
    [setShowModalLogin, showModalLogin]
  )

  useEffect(() => {
    document.addEventListener("keydown", keyPress)
    return () => document.removeEventListener("keydown", keyPress)
  }, [keyPress])

  return (
    <>
      {showModalLogin ? (
        <AccountStyles>
          <div className="overlay-bg">
            <animated.div style={animation}>
              <div className="login-register" showModalLogin={showModalLogin}>
                <div className="login">Login</div>
                <div className="title">Selamat Datang di Taman Siswa!</div>
                <div className="img-wrapper">
                  <Image imgName="TAMTAM_cropped.png" alt="TAMTAM" />
                </div>

                <form
                  className="form-login-register"
                  onSubmit={formik.handleSubmit}
                >
                  <Input
                    id="email"
                    name="email"
                    type="text"
                    placeholder="Email"
                    required
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    required
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />
                  <div className="forget-pass-wrapper">
                    <Link
                      to="/forget-password"
                      onClick={() => {
                        setShowModalLogin(false)
                      }}
                    >
                      Forget password?
                    </Link>
                  </div>
                  <Button type="submit" disabled={loading}>
                    Login
                  </Button>
                  <div className="google-signin-wrap">
                    <MyGoogleLogin
                      label="Login dengan Google"
                      disabled={loading}
                      setLoading={setLoading}
                      setIsLoggingIn={setIsLoggingIn}
                      setIsUsingGoogle={setIsUsingGoogle}
                    />
                  </div>
                  <div className="register-here">
                    Belum punya akun?{" "}
                    <span
                      style={{
                        color: "#51b848",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowModalRegister(true)
                        setShowModalLogin(false)
                      }}
                    >
                      Buat akun baru{" "}
                    </span>
                    di sini!
                  </div>
                </form>
                <CloseModalButton
                  aria-label="Close modal"
                  onClick={() => setShowModalLogin(prev => !prev)}
                />
              </div>
            </animated.div>
          </div>
        </AccountStyles>
      ) : null}
    </>
  )
}

export default withConnect(Modal)
