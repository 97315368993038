import { navigate } from "gatsby"
import { toast } from "react-toastify"
import {
  loginSuccess,
  loginFailed,
  logoutSuccess,
  logoutFailed,
  updateAccount,
  resetAccount,
} from "."
import * as authApi from "../../../services/auth"
import * as userApi from "../../../services/user"
import { checkError } from "../../../services/utils"

export function login(data) {
  return async (dispatch, _getState) => {
    try {
      dispatch(resetAccount())
      const resp = await authApi.login(data)
      dispatch(loginSuccess(resp.data))
      toast.success("Login Success!")
    } catch (error) {
      dispatch(loginFailed(error.response?.data))
      checkError(error.response?.data)
    }
  }
}

export function loginWithGoogle(data) {
  return async (dispatch, _getState) => {
    try {
      dispatch(resetAccount())
      const resp = await authApi.loginGoogle(data)
      if (resp.data?.message === "User/Passowrd Salah") {
        toast.error("User/Passowrd Salah")
      } else if (typeof resp.data === "string") {
        toast.error(resp.data)
      } else if (resp.data?.key) {
        dispatch(loginSuccess(resp.data))
        toast.success("Login Success!")
      } else {
        throw new Error()
      }
    } catch (error) {
      dispatch(loginFailed(error.response?.data))
      checkError(error.response?.data)
    }
  }
}

export function logout() {
  return async (dispatch, _getState) => {
    try {
      await authApi.logout()
      dispatch(logoutSuccess())
    } catch (error) {
      dispatch(logoutFailed())
      checkError(error.response?.data)
    } finally {
      dispatch(resetAccount())
      toast.success("Logout Success!")
      setTimeout(() => {
        navigate("/")
      }, 2000)
    }
  }
}

// no redux
export async function register(data) {
  if (data.password1 !== data.password2) {
    toast.error("Password do not match")
    throw new Error()
  }
  try {
    data.password = data.password1
    const resp = await authApi.register(data)
    toast.success("Register Success! Sending you an email...")
    return resp.data
  } catch (error) {
    checkError(error.response?.data)
    throw error
  }
}

// no redux
export async function sendVerificationEmail(email) {
  try {
    const resp = await authApi.resendVerificationEmail(email)
    toast.success("Email verification Sent!")
    return resp.data
  } catch (error) {
    checkError(error.response?.data)
    throw error
  }
}

// no redux
export async function verifyEmail(code) {
  try {
    const resp = await authApi.verifyEmail(code)
    toast.success("Email Verified!")
    return resp.data
  } catch (error) {
    checkError(error.response?.data)
    throw error
  }
}

export function editProfile(data) {
  return async (dispatch, _getState) => {
    try {
      // console.log(Array.from(data.entries()))
      await authApi.editProfile(data)
      const resp = await userApi.getMe()
      dispatch(updateAccount(resp.data))
      toast.success("Profile Updated!")
    } catch (error) {
      toast.error(error.response?.data)
      return error.response?.data
    }
  }
}

export function checkMyself() {
  return async (dispatch, _getState) => {
    if (_getState().session.isAuthenticated) {
      try {
        const resp = await userApi.getMe()
        const notif = await userApi.getNotifications()
        const temp = [...notif.data]
        temp?.sort((a, b) => {
          return b.id - a.id
        })
        dispatch(
          updateAccount({
            ...resp.data,
            notifications: temp,
          })
        )
      } catch (err) {
        if (
          err.response?.data?.detail === "Invalid token." ||
          err.response?.status === 500
        ) {
          dispatch(resetAccount())
          toast.error("Your session has expired. Please login again")
        }
      }
    }
  }
}
