// Generic imports
import React from "react"
import styled from "styled-components"

// Other components imports

// Redux imports

// Static imports

// Utils imports

const Styles = styled.div`
  display: inline-block;
  width: 100%;

  label {
    color: #51b848;
  }

  input {
    width: 100%;
  }
`

export default function Input(props) {
  const { label, id, ...otherProps } = props
  const inputId = id !== null ? id : props.name
  // const inputId = id

  return (
    <Styles className="taman-input">
      {label ? <label htmlFor={inputId}>{label}{otherProps?.required ?
        <span style={{ color: "#F5303C" }}>*</span>
      : <></>}</label> : <></>}
      <input id={inputId} {...otherProps} />
    </Styles>
  )
}
