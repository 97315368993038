/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
// Generic imports
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { ToastContainer } from "react-toastify"

// Other components imports
import DashboardNotif from "components/Dashboard/dashboard-notif"
import Footer from "components/footer"
import Header from "components/header"

// Redux imports

// Static imports
import "components/bootstrap.min.css"
import "react-toggle/style.css"
import "react-toastify/dist/ReactToastify.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "components/layout.css"
import { StyledLayout } from "./layoutCSS"

// Utils imports
import { initAmplitude } from "trackers/amplitude"

const Layout = ({ children, noFooter = false }) => {
  const [popupNotif, setPopupNotif] = useState(0)

  useEffect(() => {
    initAmplitude()
  }, [])

  const toggleNotif = () => {
    if (popupNotif === 0) {
      setPopupNotif(1)
    } else {
      setPopupNotif(0)
    }
  }

  return (
    <StyledLayout>
      <Header toggleNotif={toggleNotif} />
      <div id="all-wrapper">
        <main>{children}</main>
        {noFooter ? <></> : <Footer />}
      </div>
      {popupNotif === 1 ? (
        <div className="dashboard-notif-overlay">
          <div>
            <DashboardNotif toggleNotif={toggleNotif} />
          </div>
        </div>
      ) : (
        <></>
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </StyledLayout>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
