// Generic imports
import React from "react"
import styled from "styled-components"

// Other components imports

// Redux imports

// Static imports

// Utils imports

export const AccountStyles = styled.div`
  z-index: 1220;

  .taman-logo {
    max-width: 400px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .login {
    position: absolute;
    top: 22px;
    left: 68px;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
    font-size: 1.1em;
  }

  .overlay-bg {
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: #c4c4c4;
    position: fixed;
    z-index: 1320;
    overflow-y: auto;
  }

  .login-register {
    max-width: 433.08px;
    width: 90%;
    margin: auto;
    background: #fff;
    position: relative;
    z-index: 10;
    border-radius: 10px;
    margin-top: 40px;
    margin-bottom: 80px;

    .img-wrapper {
      width: 320px;
      text-align: center;
      margin: auto;
    }

    .title {
      padding: 60px 20px 0 30px;
      font-size: 30px;
      font-weight: 700;
    }

    .sub {
      max-width: 308px;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 6px;
      font-size: 14px;
      font-weight: 400;
    }

    a {
      color: #51b848 !important;
      text-decoration: none;
    }

    .form-login-register {
      margin: auto;
      text-align: center;
      z-index: 3000;

      input {
        margin: 10px auto;
        max-width: 329px;
        width: 100%;
        background-color: #eeeeee;
        border: transparent;
      }

      .forget-pass-wrapper {
        max-width: 329px;
        width: 90%;
        margin: auto;
        text-align: right;

        a {
          color: gray !important;
          text-decoration: underline;
        }
      }

      button {
        max-width: 329px;
        width: 90%;
        margin: 10px auto;
        text-align: center;
      }

      .logo-wrapper {
        width: 14.4px;
        margin: 4px 6px;
      }

      .phone-input {
        padding-bottom: 15px;
      }

      .register-here {
        padding-bottom: 20px;
        text-align: left;
        padding: 10px 30px 30px 30px;
      }

      .terms {
        padding: 10px 30px 0px 30px;
        font-size: 12px;
        font-weight: 300;
        text-align: left;
      }
    }
  }

  .register-here {
    text-align: center;
    color: gray;
  }
`

export default function AccountWrapper({ children }) {
  return (
    <AccountStyles>
      <div className="account-wrapper-top layout">{children}</div>
    </AccountStyles>
  )
}
