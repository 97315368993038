/* eslint-disable camelcase */
import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"

import Button from "components/Button"

import * as sessionSelectors from "rdx/slices/session/selectors"
import { formatDateTime } from "services/utils"

const Styles = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 2rem;

  .close-notif {
    font-weight: bold;
    font-size: 1.5em;
    padding-bottom: 1rem;
    padding-left: 15px;
    padding-right: 15px;

    span {
      cursor: pointer;
    }

    span:hover {
      opacity: 0.5;
    }
  }

  .profil-section {
    text-align: center;

    .profil-photo {
      max-width: 140px;
      width: 100%;
      margin: auto;
    }

    .profil-photo > div {
      padding-bottom: 100%;
    }

    h4 {
      padding-top: 2rem;
      padding-bottom: 1rem;
    }

    small {
      color: #a0a4a8;
    }
  }

  .list-notifikasi {
    .title-notifikasi {
      color: #52575c;
    }

    .card-notifikasi {
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
      background: #ffffff;
      border: 1px solid rgba(81, 184, 72, 0.5);
      box-sizing: border-box;
      border-radius: 8px;
      padding: 15px 12px;
      margin-top: 12px;
      margin-bottom: 12px;

      h4 {
        margin-bottom: 0;
        padding-bottom: 0.4rem;
      }

      small {
        display: block;
        color: #a0a4a8;
        padding-bottom: 0.2rem;
      }

      .invitation-btn {
        text-align: right;

        button {
          border-radius: 8px;
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }
  }
`

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

function DashboardNotif({ toggleNotif, user }) {
  return (
    <Styles>
      <div className="close-notif">
        <span onClick={toggleNotif}>X</span>
      </div>
      <div className="profil-section">
        <div className="profil-photo">
          <div
            style={{
              background: `url(${user.photo}) no-repeat center center`,
              borderRadius: "50%",
              width: "100%",
              backgroundSize: "cover",
            }}
          />
        </div>
        <h4>{user.first_name + " " + user.last_name}</h4>
      </div>
      <div className="list-notifikasi">
        <h4 className="title-notifikasi">Notifikasi</h4>
        {user.notifications?.length > 0 ? (
          user.notifications.map(
            ({ title, date, message, action_msg, action_url }, index) => (
              <div className="card-notifikasi" key={index}>
                <h4>{title}</h4>
                <small>{formatDateTime(date)}</small>
                <div>{message}</div>
                {action_msg ? (
                  <div className="invitation-btn">
                    <Button href={action_url}>{action_msg}</Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )
          )
        ) : (
          <div style={{ color: "#6a6a6a" }}>Nothing to show</div>
        )}
      </div>
    </Styles>
  )
}

export default withConnect(DashboardNotif)
