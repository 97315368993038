// Generic imports
import React from "react"
import styled from "styled-components"

// Other components imports
import Image from "components/image"
import { Link } from "gatsby"

// Redux imports

// Static imports
import logoHD from "images/TamanSchool - black.png"
// import mail from "../images/email-vector.png"
// import ig from "../images/ig-vector.png"
// import link from "../images/link-vector.png"
// import twt from "../images/twt-vector.png"

// Utils imports
import { sendAmplitudeData } from "trackers/amplitude"

const Styles = styled.footer`
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  background: #ffffff;

  .footer-brand img {
    width: 100%;
  }

  .each-sosmed {
    display: inline-block;
  }

  .sosmed-ig {
    position: relative;
    bottom: 4px;
  }

  .social-media a:hover {
    text-decoration: none;
    opacity: 0.8;
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    .links {
      line-height: 1.5;
    }

    .links h4 {
      font-size: 0.8rem;
    }

    .links a,
    span {
      font-size: 0.8rem;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    .brand-email {
      padding-bottom: 0.75vw;
      font-size: 1rem;
    }
  }

  a {
    color: black;
    font-style: normal;
  }

  .footer-brand {
    display: block;
    font-size: 1.75em;
    width: 200px;
    margin-left: -31px;
  }

  .links {
    line-height: 1.75;
  }

  .links h4 {
    font-size: 1rem;
  }

  .each-sosmed {
    width: 20px;
  }

  .brand-email {
    padding-bottom: 5px;
  }

  @media screen and (max-width: 767px) {
    .footer-brand {
      margin-left: -21px;
    }
  }
`

const Footer = () => (
  <Styles>
    <div className="container pb-4">
      <div className="row">
        <div className="col-lg-3 col-md-4 pt-4">
          <div className="footer-brand">
            <Link
              onClick={() => {
                sendAmplitudeData("FOOTER_LOGO_CLICKED")
              }}
              to="/"
            >
              <img src={logoHD} alt="Logo" />
            </Link>
          </div>
          <div className="py-2" style={{ fontWeight: "800", fontSize: "16px" }}>
            Contact us at:
          </div>
        
          <div className="social-media pb-1">
            <a
              href="mailto:tamanschool.id@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                sendAmplitudeData("FOOTER_EMAIL_CLICKED")
              }}
            >
              <div className="each-sosmed">
                <Image imgName="email-vector.png" alt="email" />
              </div>
              <span className="sosmed-ig"> tamansiswa.indo@gmail.com</span>
            </a>
          </div>

          <div className="social-media pb-1">
            <a
              href="https://www.instagram.com/tamanschool_/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                sendAmplitudeData("FOOTER_INSTAGRAM_CLICKED")
              }}
            >
              <div className="each-sosmed">
                <Image imgName="ig-vector.png" alt="instagram" />
              </div>
              <span className="sosmed-ig"> tamanschool_</span>
            </a>
          </div>

          <div className="social-media pb-1">
            <a
              href="https://twitter.com/tamanschool/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                sendAmplitudeData("FOOTER_TWITTER_CLICKED")
              }}
            >
              <div className="each-sosmed">
                <Image imgName="twt-vector.png" alt="twitter" />
              </div>
              <span className="sosmed-ig"> @tamanschool</span>
            </a>
          </div>

        </div>
        {/* <div className="col-md-1"></div> */}
        <div className="col-lg-3 col-md-4 pt-4 links">
          <h4 className="title pb-2">Products</h4>
          <Link
            onClick={() => {
              sendAmplitudeData("FOOTER_ABOUT_CLICKED")
            }}
            to="/tamansiswa"
          >
            TamanClass+
          </Link>
          <br />
          <span>TamanClass Seikhlasnya</span>
          <br />
          <span>Talamus</span>
          <br />

          <h4 className="title pt-4 pb-2">Services</h4>
          <Link
            onClick={() => {
              sendAmplitudeData("FOOTER_ABOUT_CLICKED")
            }}
            to="/tamansiswa"
          >
            How to pay Seikhlasnya
          </Link>
        </div>
        <div className="col-lg-3 col-md-4 pt-4 links">
          <h4 className="title pb-2">About Taman School</h4>
          <span>Team</span>
          <br />
          <span>History</span>
          <br />
          <span>Blog</span>
          <br />

          <h4 className="title pt-4 pb-2">For Siswa</h4>
          <span>Passion Test</span>
          <br />
          <span>Request for Classes</span>
          <br />

          <h4 className="title pt-4 pb-2">For Educators</h4>
          <span>How to make a Class</span>
          <br />
          <span>#TutorPenggerak Program</span>
          <br />
          <span>Diary Tutor</span>
          <br />
        </div>
        <div className="col-lg-3 col-md-4 pt-4 links">
          <h4 className="title pb-2">TamanSchool Values</h4>
          <span>The right soil for growth</span>
          <br />
          <span>Diversity in creativity</span>
          <br />
          <span>Empowered dedication</span>
          <br />
          <span>Accesibility</span>
          <br />
        </div>
      </div>
      <div className="row pt-3" style={{ marginTop: "50px" }}>
        <div className="col">
          <small style={{ color: "rgba(51, 51, 51, 0.5)" }}>
            Copyright © 2020 Taman School. All Rights Reserved.
          </small>
        </div>
      </div>
    </div>
  </Styles>
)

export default Footer
