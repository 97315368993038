import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"

// Other components imports
import { GoogleLogin } from "react-google-login"

// Redux imports
import * as sessionThunks from "rdx/slices/session/thunks"

// Static imports
import { googleClientId } from "services/auth"

const StyledLoginWrapper = styled.div`
  .ButtonGoogle {
    border-radius: 10px !important;
    border: 1px solid #e7e7e7 !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12) !important;

    & > div {
      border-radius: 10px !important;
    }

    & > span {
      width: calc(100% - 38px);
      letter-spacing: 1.25px;
    }
  }
`

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  loginWithGoogle: sessionThunks.loginWithGoogle,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

function MyGoogleLogin({
  loginWithGoogle,
  label,
  disabled,
  setLoading,
  setIsLoggingIn,
  setIsUsingGoogle,
}) {
  const responseGoogleSuccess = response => {
    // console.log(response)
    setLoading(true)
    setIsLoggingIn(true)
    setIsUsingGoogle(true)
    loginWithGoogle({
      access_token: response.accessToken,
      id_token: response.tokenId,
    }).finally(() => {
      setLoading(false)
    })
  }

  const responseGoogleFailed = response => {
    // console.log(response)
  }

  return (
    <StyledLoginWrapper>
      <GoogleLogin
        className="ButtonGoogle"
        clientId={googleClientId}
        onSuccess={responseGoogleSuccess}
        onFailure={responseGoogleFailed}
        cookiePolicy={"single_host_origin"}
        disabled={disabled}
      >
        {label ?? "Masuk dengan Google"}
      </GoogleLogin>
    </StyledLoginWrapper>
  )
}

export default withConnect(MyGoogleLogin)
