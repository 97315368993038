// Generic imports
import React, { useState, useEffect, useCallback } from "react"
import { useSpring, animated } from "react-spring"
import styled from "styled-components"
import { MdClose } from "react-icons/md"
import { toast } from "react-toastify"
import { connect } from "react-redux"
import { navigate } from "gatsby"

// Other components imports
import { AccountStyles } from "components/Account/index"
import Button from "components/Button"
import Input from "components/Reusable/Input"
import { useFormik } from "formik"
import MyGoogleLogin from "components/Account/GoogleLogin"

// Redux imports
import * as sessionSelectors from "rdx/slices/session/selectors"
import * as sessionThunks from "rdx/slices/session/thunks"

// Static imports

// Utils imports
import { redirectWithHistory } from "services/utils"

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 19px;
  left: 25px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

function RegisterComponent({
  showModalRegister,
  setShowModalLogin,
  setShowModalRegister,
  user,
  redirectTo,
}) {
  const [loading, setLoading] = useState(false)
  const [isUsingGoogle, setIsUsingGoogle] = useState(false)
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [showEmailRegister, setShowEmailRegister] = useState(false)

  useEffect(() => {
    if (isLoggingIn && user.email) {
      formik.setFieldValue("email", "")
      formik.setFieldValue("password", "")
      setIsLoggingIn(false)
      setShowModalRegister(false)
      if (user.is_verified) {
        if (user.occupation) {
          if (redirectTo) {
            redirectWithHistory(redirectTo)
          } else {
            navigate("/")
          }
        } else {
          navigate("/verification?step=2")
        }
      } else {
        if (isUsingGoogle) {
          sessionThunks.sendVerificationEmail(user.email).finally(() => {
            setTimeout(() => {
              navigate("/verification")
            }, 1000)
          })
        }
      }
    }
  }, [user, isLoggingIn])

  const animation = useSpring({
    config: {
      duration: 250,
    },
    position: "relative",
    opacity: showModalRegister ? 1 : 0,
    top: showModalRegister ? `0%` : `-100%`,
  })

  const keyPress = useCallback(
    e => {
      if (e.key === "Escape" && showModalRegister) {
        setShowEmailRegister(false)
        setShowModalRegister(false)
      }
    },
    [setShowModalRegister, showModalRegister]
  )

  useEffect(() => {
    document.addEventListener("keydown", keyPress)
    return () => document.removeEventListener("keydown", keyPress)
  }, [keyPress])

  const formik = useFormik({
    initialValues: {
      fullname: "",
      email: "",
      password1: "",
      password2: "",
    },
    onSubmit: async values => {
      try {
        setLoading(true)
        const newValues = {
          ...values,
          username: values.email,
        }
        if (values.fullname.trim() !== "") {
          const splittedNames = values.fullname.trim().split(" ")
          newValues.first_name = splittedNames[0]
          if (splittedNames.length > 1) {
            newValues.last_name = splittedNames.slice(1).join(" ")
          }

          await sessionThunks.register(newValues)
          await sessionThunks.sendVerificationEmail(newValues.email)
          setShowEmailRegister(false)
          setShowModalLogin(true)
          setShowModalRegister(false)
        } else {
          toast.error("Name must not be empty")
        }
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <>
      {showModalRegister ? (
        <AccountStyles>
          <div className="overlay-bg">
            <animated.div style={animation}>
              <div className="login-register">
                <div className="login">Daftar</div>
                <div className="title">Selamat Datang di Taman Siswa!</div>
                <div className="sub">
                  Belum punya akun? Silakan daftarkan akunmu di sini!
                </div>
                {showEmailRegister ? (
                  <form
                    onSubmit={formik.handleSubmit}
                    className="form-login-register"
                  >
                    <Input
                      id="fullname"
                      name="fullname"
                      type="text"
                      placeholder="Nama Lengkap"
                      required
                      onChange={formik.handleChange}
                      value={formik.values.fullname}
                    />
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      required
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    <Input
                      id="password1"
                      name="password1"
                      type="password"
                      placeholder="Password"
                      required
                      onChange={formik.handleChange}
                      value={formik.values.password1}
                    />
                    <Input
                      id="password2"
                      name="password2"
                      type="password"
                      placeholder="Konfirmasi Password"
                      required
                      onChange={formik.handleChange}
                      value={formik.values.password2}
                    />
                    <Button type="submit" disabled={loading}>
                      {loading ? "Loading..." : "Daftarkan"}
                    </Button>
                    <div className="terms">
                      By signing up, Anda berarti menyetujui segala ketentuan
                      yang berlaku dalam Terms and Condition yang berlaku di
                      TamanSiswa
                    </div>
                    <div className="register-here">
                      Sudah punya akun?{" "}
                      <span
                        style={{
                          color: "#51b848",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowEmailRegister(false)
                          setShowModalLogin(true)
                          setShowModalRegister(false)
                        }}
                      >
                        Login
                      </span>{" "}
                      disini
                    </div>
                  </form>
                ) : (
                  <div className="form-login-register">
                    <div
                      className="google-signin-wrap"
                      style={{
                        marginTop: "14px",
                      }}
                    >
                      <MyGoogleLogin
                        label="Daftar dengan Google"
                        disabled={loading}
                        setLoading={setLoading}
                        setIsLoggingIn={setIsLoggingIn}
                        setIsUsingGoogle={setIsUsingGoogle}
                      />
                    </div>
                    <Button
                      style={{
                        marginBottom: "4px",
                      }}
                      onClick={() => setShowEmailRegister(true)}
                    >
                      Daftar dengan Email
                    </Button>
                    <div className="terms">
                      By signing up, Anda berarti menyetujui segala ketentuan
                      yang berlaku dalam Terms and Condition yang berlaku di
                      TamanSiswa
                    </div>
                    <div className="register-here">
                      Sudah punya akun?{" "}
                      <span
                        style={{
                          color: "#51b848",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowModalLogin(true)
                          setShowModalRegister(false)
                        }}
                      >
                        Login
                      </span>{" "}
                      disini
                    </div>
                  </div>
                )}
                <CloseModalButton
                  aria-label="Close modal"
                  onClick={() => {
                    setShowEmailRegister(false)
                    setShowModalRegister(prev => !prev)
                  }}
                />
              </div>
            </animated.div>
          </div>
        </AccountStyles>
      ) : null}
    </>
  )
}

export default withConnect(RegisterComponent)
