import styled from "styled-components"

export const StyledLayout = styled.div`
  & > .dashboard-notif-overlay {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1020;
    background: rgba(0, 0, 0, 0.5);
  }

  & > .dashboard-notif-overlay > div {
    max-width: 400px;
    width: 100%;
    margin-left: auto;
    background: white;
    height: 100vh;
    padding: 15px;
    overflow-y: auto;
  }
`
