import styled from "styled-components"

export const StyledHeader = styled.header`
  background-color: #ffffff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1002;
  padding-top: 1px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

  .tamansiswalogo {
    width: 100%;
  }

  .navbar {
    padding: 0.5rem 1rem;
  }

  .navbar-brand {
    height: 100%;
    width: 175px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .navbar-light .navbar-nav .nav-link {
    letter-spacing: 0.25px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 0;
  }

  .notification-text {
    display: none;
  }

  .navbar-light .navbar-nav .notification-link {
    cursor: pointer;
    height: 42px;
    padding: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0;
    color: #333333;
    font-weight: bold;
  }

  .notif-logged-in-wrap {
    display: flex;
    align-items: center;
    margin-left: 1rem;

    .register-btn {
      background: #f5fff5;
      border: 1px solid #cbe9c8;
      color: #51b848;
      border-radius: 6px;
    }
  }

  .notif-image-wrapper {
    width: 20px;
    display: inline-block;
  }

  .dropdown-item {
    color: #4e5154;
  }

  .navbar-light .navbar-nav .notification-link:hover {
    opacity: 0.8;
  }

  .navbar-light .navbar-nav .nav-link:hover {
    color: #000000;
  }

  .about-dropdown {
    padding-right: 10px;
  }

  .collapse-right {
    padding-left: 10px;
    padding-right: 10px;
    /* border-style: ridge;
    border-left-width: 1px;
    border-right-width: 0px;
    border-top-width: 0px;
    border-bottom: 0px; */
  }

  .dropdown-menu {
    top: calc(100% + 8px);
    margin-top: 0;
  }

  .notif-logged-in-wrap {
    button {
      margin-left: 4px;
      margin-right: 4px;
    }
  }

  button {
    border-radius: 10px;
  }

  .dropdown-menu {
    min-width: 7rem;
  }

  .dropdown-item {
    cursor: pointer;
    padding: 0.3rem 1.2rem;
  }

  .dropdown-item:focus {
    background: #51b848;
    color: white !important;
  }

  .profile-wrapper {
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 22px;
    margin-left: 10px;
    min-width: 110px;
    display: flex;
    align-items: center;
  }

  .navbar-light .navbar-nav .profile-wrapper .nav-link {
    margin-right: 0;
  }

  .dropdown-toggle::after {
    vertical-align: 0.15em;
    margin-left: 6px;
    margin-right: 6px;
  }

  .profile-pic-wrapper {
    display: inline-block;
    width: 26px;
    position: relative;
    left: 6px;
  }

  .profile-pic {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    z-index: 1;

    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .dib {
    display: inline-block;
  }

  @media screen and (max-width: 991.98px) {
    .navbar-brand {
      padding-bottom: 10px;
      margin-left: -10px;
    }

    .collapse-right {
      padding-left: 0;
      padding-top: 0;
      border-left-width: 0px;
    }

    .notif-logged-in-wrap {
      padding: 0;
      flex-wrap: wrap;

      & > div {
        width: 100%;
      }

      button {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 8px;
      }
    }

    .profile-wrapper {
      border: none;
      margin: 0;
    }

    .profile-wrapper .dib {
      display: block;
    }

    .profile-pic-wrapper {
      display: none;
    }

    .navbar-light .navbar-nav {
      .notification-link {
        display: flex;
      }

      .notification-link,
      .profile-wrapper .nav-link {
        padding-left: 0;
        margin-left: 0;
      }
    }

    .notification-text {
      display: inline-block;
      padding-right: 6px;
    }

    .notif-logged-in-wrap {
      /* flex-direction: column; */
      justify-content: space-between;
    }
  }
`
