// Generic imports
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { toast } from "react-toastify"

// Other components imports
import Button from "components/Button"
import Image from "components/image"
import { Link } from "gatsby"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import { StyledHeader } from "./headerCSS"
import Login from "components/Account/Login"
import Register from "components/Account/Register"

// Redux imports
import * as sessionSelectors from "rdx/slices/session/selectors"
import * as sessionThunks from "rdx/slices/session/thunks"

// Static imports
import logoHD from "images/TamanSchool - black.png"
import blankPicture from "images/blank-profile-green.png"

// Utils imports
import { trackNavbarButtonClicked } from "trackers/amplitude"

const mapStateToProps = state => ({
  token: sessionSelectors.getUserToken(state),
  user: sessionSelectors.getUser(state),
  authError: sessionSelectors.getError(state),
})

const mapDispatchToProps = {
  checkMyself: sessionThunks.checkMyself,
  logout: sessionThunks.logout,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const Header = props => {
  const { user, token, checkMyself, logout, toggleNotif, authError } = props

  useEffect(() => {
    if (token) {
      checkMyself()
    }
  }, [token])

  useEffect(() => {
    if (authError) {
      toast.error(authError)
    }
  }, [authError])

  const [showModalLogin, setShowModalLogin] = useState(false)

  // const openModalLogin = () => {
  //   setShowModalLogin(prev => !prev)
  // }

  const [showModalRegister, setShowModalRegister] = useState(false)

  const openModalRegister = () => {
    setShowModalRegister(prev => !prev)
  }

  let shortName = `${user.first_name} ${user.last_name}`.trim()
  if (shortName.length >= 15) {
    shortName = shortName.slice(0, 12) + "..."
  }
  if (shortName === "") {
    shortName = "Anonim"
  }

  return (
    <StyledHeader>
      <Navbar
        expand="lg"
        style={{
          boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.075)",
        }}
      >
        <Navbar.Brand>
          <Link onClick={() => trackNavbarButtonClicked("LOGO_TO_HOME")} to="/">
            <img src={logoHD} alt="Logo" className="tamansiswalogo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Link
              className="nav-link"
              onClick={() => trackNavbarButtonClicked("CLASSES")}
              to="/classes"
            >
              Class Catalogue
            </Link>
            <Link
              className="nav-link"
              onClick={() => trackNavbarButtonClicked("DROPDOWN_TUTOR")}
              to="/tutor-dashboard"
            >
              Become A Tutor
            </Link>
            <Link
              className="nav-link"
              onClick={() => trackNavbarButtonClicked("TAMANSISWA")}
              to="/tamansiswa"
            >
              About Us
            </Link>
            <Link
              className="nav-link"
              onClick={() => trackNavbarButtonClicked("CONTACT_US")}
              to="/contact-us"
            >
              Contact Us
            </Link>
            {user.email ? (
              <div className="notif-logged-in-wrap">
                <div
                  className="notification-link"
                  onClick={() => {
                    trackNavbarButtonClicked("NOTIFICATION")
                    toggleNotif()
                  }}
                >
                  <span className="notification-text">Notifications</span>
                  <div className="notif-image-wrapper">
                    <Image imgName="notification-bell.png" alt="notification" />
                  </div>
                </div>
                <div className="collapse-right">
                  <div className="profile-wrapper">
                    <div className="profile-pic-wrapper">
                      <Link
                        onClick={() =>
                          trackNavbarButtonClicked("PROFILE_PICTURE")
                        }
                        to="/dashboard"
                        className="nav-link profile-pic"
                        style={{
                          backgroundImage: `${
                            user.photo
                              ? `url(${user.photo})`
                              : `url(${blankPicture})`
                          }`,
                        }}
                      ></Link>
                    </div>
                    <NavDropdown title={shortName} className="dib">
                      <Link
                        className="dropdown-item"
                        to="/dashboard"
                        onClick={() =>
                          trackNavbarButtonClicked("DROPDOWN_DASHBOARD")
                        }
                      >
                        Dashboard
                      </Link>
                      <div
                        className="dropdown-item"
                        onClick={() => {
                          trackNavbarButtonClicked("DROPDOWN_LOGOUT")
                          logout()
                        }}
                      >
                        Logout
                      </div>
                    </NavDropdown>
                  </div>
                </div>
              </div>
            ) : (
              <div className="notif-logged-in-wrap">
                {/* <Button
                  onClick={openModalLogin}
                  className="secondary login-btn"
                >
                  Log In
                </Button> */}
                <Button onClick={openModalRegister} className="register-btn">
                  Register
                </Button>
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Login
        showModalLogin={showModalLogin}
        setShowModalLogin={setShowModalLogin}
        setShowModalRegister={setShowModalRegister}
      />
      <Register
        showModalRegister={showModalRegister}
        setShowModalLogin={setShowModalLogin}
        setShowModalRegister={setShowModalRegister}
      />
    </StyledHeader>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  toggleNotif: PropTypes.func,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default withConnect(Header)
