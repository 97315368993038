// Generic imports
import React, { Component } from "react"
import styled from "styled-components"

// Other components imports
import { Link } from "gatsby"

// Redux imports

// Static imports

// Utils imports

export const Styles = styled.button`
  background: #51b848;
  border: 2px solid #51b848;
  border-radius: 10px;
  color: white;
  font-weight: normal;
  padding-top: 7px;
  padding-bottom: 6px;
  padding-left: 18px;
  padding-right: 18px;
  letter-spacing: 1.25px;
  transition: 0.3s ease-in-out;

  &:hover {
    opacity: 0.7;
    transition: 0.3s ease-in-out;
  }

  &:disabled {
    opacity: 0.65;
  }

  &.secondary {
    background: white;
    color: #51b848;
  }

  &.red {
    border-color: #f16ba9;
    background: white;
    color: #f16ba9;
  }

  &.yellow {
    border-color: #eccb45;
    background: white;
    color: #eccb45;
  }

  &.grey {
    display: flex;
    background: white;
    border: 1px solid rgba(231, 231, 231, 1);
    color: rgba(59, 59, 59, 1);
    box-shadow: rgba(0, 0, 0, 0.12);
  }

  &.with-shadow {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  }
`

class Button extends Component {
  render() {
    const props = this.props
    if (props.to) {
      return (
        <Link to={props.to}>
          <Styles {...props}>{props.children}</Styles>
        </Link>
      )
    }
    if (props.newPage && props.href) {
      return (
        <a href={props.href} target="_blank" rel="noopener noreferrer">
          <Styles {...props}>{props.children}</Styles>
        </a>
      )
    }
    if (props.href) {
      return (
        <a href={props.href}>
          <Styles {...props}>{props.children}</Styles>
        </a>
      )
    }
    return <Styles {...props}>{props.children}</Styles>
  }
}

export default Button
